export const testimonials = [
  {
    name: 'John Doe',
    text: 'This portfolio is amazing!',
  },
  {
    name: 'Jane Smith',
    text: 'I love the design and functionality!',
  },
  // Add more testimonials
];
